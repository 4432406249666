import axios from 'axios';

export async function fetchApi(path: string, options: any, onToastError?: any) {
    try {
        const results = await axios.get(path, {
            ...options,
        });
        return results.data;
    } catch (err: any) {

        if (onToastError) {
            onToastError(err.message);
        }
        console.error(
            `Initial fetch from API encountered an error: ${err?.message}`
        );
    }
}
