import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { lightTheme, darkTheme } from "./themes";
import { AppProvider } from "./AppContext";
import { Helmet, HelmetProvider } from "react-helmet-async";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import locale from "./locales/en-US.json";
import App from "./App";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
let context: any = {
    apiUrl: "",
    lightTheme,
    darkTheme,
    locale,
};

const _window: any = window;
const basename = process.env.REACT_APP_BASENAME || '';

if (_window?.__apiUrl !== undefined) {
    context.apiUrl = _window?.__apiUrl;
}

root.render(
    <React.StrictMode>
        <ThemeProvider theme={context.lightTheme}>
            <HelmetProvider>
                <Helmet>
                    <title>{context.locale.app.meta.title}</title>
                    <meta
                        name="description"
                        content={context.locale.app.meta.description}
                    />
                </Helmet>
            </HelmetProvider>
            <AppProvider preContext={context}>
                <BrowserRouter basename={basename}>
                    <App />
                </BrowserRouter>
            </AppProvider>
        </ThemeProvider>
    </React.StrictMode>
);
