import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import { v4 as uuidv4 } from "uuid";
import { useAppContext } from "./AppContext";
import { Box } from "@mui/material";
import { fetchApi } from "./helpers/fetchApi";
import { TopLinks } from "./components/shared/TopLinks";
import { LazyDynamicComponent } from "./components/DynamicComponent/LazyDynamicComponent";
import { Loading } from "./components/shared/Loading";
import { LazyDrawerNavigation } from "./components/DrawerNavigation/LazyDrawerNavigation";
import { useMediaQuery, Theme } from "@mui/material";
import { MenuItem } from "./app-interfaces/interfaces";

const App = () => {
  const { state } = useAppContext();
  const [loading, setLoading] = useState(true);
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const [logo, setLogo] = useState("");
  const [geniusURL, setGeniusURL] = useState("");
  const [footerData, setFooterData]: any = useState({});
  const [noMatchData, setNoMatchData]: any = useState({});
  const [loginData, setLoginData]: any = useState({});
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    async function getConfig() {
      try {
        const path = `${state.apiUrl}config`;
        const results = await fetchApi(path, { signal });
        const menu = results?.contents?.menu
          ?.filter((item: any) => item?.isPublic)
          ?.sort((a: any, b: any) => a.position - b.position);
        if (results) {
          setMenuItems(menu);
          setLoginData({
            loginUrl: results?.contents?.loginUrl,
            loginText: results?.contents?.loginText,
            logoutText: results?.contents?.logoutText,
            createAccountUrl: results?.contents?.createAccountUrl,
            createAccountText: results?.contents?.createAccountText,
          });
          setLogo(results?.contents?.bannerSmallUrl);
          setGeniusURL(results?.contents?.geniusURL);
          setFooterData({ body: results?.contents?.footer });
          setNoMatchData({ body: results?.contents?.notFoundContent });
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching configuration:", error);
      }
    }

    getConfig();

    return () => {
      controller.abort(state.locale.app.main.menuItems.abort);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.apiUrl]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <LazyDrawerNavigation
        logo={logo}
        menuItems={menuItems}
        loginData={loginData}
        drawerWidth={{ xs: "100%", sm: 240 }}
      />
      <Box
        component="main"
        sx={{ flex: 1, padding: { xs: "0.5rem", sm: "1rem", md: "1.5rem" } }}
      >
        <Routes>
          <Route path="/" element={<TopLinks links={loginData} />}>
            <Route
              index
              element={
                <LazyDynamicComponent
                  data={menuItems.find(
                    (item: MenuItem) => item?.position === 0
                  )}
                  geniusURL={geniusURL}
                  isFooter={false}
                  isNotFound={false}
                />
              }
            />
            <Route
              path={`/${(
                menuItems.find((item: MenuItem) => item?.position === 0) as any
              )?.title
                .toLowerCase()
                .replace(/\s+/g, "-")}`}
              element={
                <>
                  <LazyDynamicComponent
                    data={menuItems.find((item: any) => item?.position === 0)}
                    isFooter={false}
                    geniusURL={geniusURL}
                    isNotFound={false}
                  />
                </>
              }
            />
            {menuItems &&
              menuItems.map((menuItem: MenuItem) => {
                const path =
                  menuItem?.position === 0
                    ? "/"
                    : `/${menuItem?.title.toLowerCase().replace(/\s+/g, "-")}`;
                return (
                  <Route
                    key={uuidv4()}
                    path={path}
                    element={
                      <LazyDynamicComponent
                        data={menuItem}
                        geniusURL={geniusURL}
                        isFooter={false}
                        isNotFound={false}
                      />
                    }
                  >
                    {menuItem?.submenu && menuItem?.submenu?.length > 0 && (
                      <>
                        <Route
                          index
                          element={
                            <React.Suspense fallback={<Loading />}>
                              <LazyDynamicComponent
                                data={menuItem.submenu[0]}
                                geniusURL={geniusURL}
                                isFooter={false}
                                isNotFound={false}
                              />
                            </React.Suspense>
                          }
                        />
                        {menuItem?.submenu?.map((subItem: any) => {
                          const subPath = `${subItem?.title
                            ?.toLowerCase()
                            .replace("%20", "-")
                            .replace(" ", "-")}`;
                          return (
                            <Route
                              key={uuidv4()}
                              path={subPath}
                              element={
                                <React.Suspense fallback={<Loading />}>
                                  <LazyDynamicComponent
                                    data={subItem}
                                    geniusURL={geniusURL}
                                    isFooter={false}
                                    isNotFound={false}
                                  />
                                </React.Suspense>
                              }
                            />
                          );
                        })}
                      </>
                    )}
                  </Route>
                );
              })}
            <Route
              path="*"
              element={
                <LazyDynamicComponent
                  data={noMatchData}
                  geniusURL={geniusURL}
                  isNotFound
                  isFooter={false}
                />
              }
            />
          </Route>
        </Routes>
      </Box>
      <Box
        component="footer"
        sx={{
          mt: "auto",
          py: { xs: 1, sm: 2 },
          px: { xs: 1, sm: 2 },
          backgroundColor: "#f0f0f0",
        }}
      >
        <LazyDynamicComponent
          data={footerData}
          isFooter
          isNotFound={false}
          geniusURL={geniusURL}
        />
      </Box>
    </Box>
  );
};

export default App;
