import React, { createContext, useContext, useState, ReactNode } from "react";
import { v4 as uuid } from "uuid";
import { Snackbar, SnackbarOrigin, SnackbarContent } from "@mui/material";
import Slide, { SlideProps } from "@mui/material/Slide";
interface SnackState extends SnackbarOrigin {
  open: boolean;
  style: object;
}

// Define the shape of your context's value
interface AppContextValue {
  state: any;
  setState: React.Dispatch<React.SetStateAction<any>>;
  toast: any;
}

interface AppProviderProps {
  children: ReactNode;
  preContext: any;
}

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="down" />;
}

export const AppProvider = ({ preContext, children }: AppProviderProps) => {
  const [state, setState] = useState<any>({ ...preContext });
  const [message, setMessage] = useState("");
  const [snackState, setSnackState] = useState<SnackState>({
    open: false,
    vertical: "top",
    horizontal: "center",
    style: {},
  });

  const { vertical, horizontal, open, style } = snackState;

  const handleClose = () => {
    setSnackState({ ...snackState, open: false });
  };

  const showToast = () =>
    setSnackState({
      vertical: "top",
      horizontal: "center",
      open: true,
      style: { bgcolor: "secondary.main", color: "white" },
    });
  const showErrorToast = () =>
    setSnackState({
      vertical: "top",
      horizontal: "center",
      open: true,
      style: { bgcolor: "error.main", color: "white" },
    });

  const onToastError = (e: any) => {
    if (e && e !== undefined) {
      setMessage(e);
    }
    showErrorToast();
  };

  return (
    <AppContext.Provider
      value={{
        state,
        setState,
        toast: {
          message,
          setMessage,
          vertical,
          horizontal,
          open,
          style,
          handleClose,
          showErrorToast,
          showToast,
          onToastError,
          snackState,
          setSnackState,
        },
      }}
    >
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        key={uuid()}
        TransitionComponent={SlideTransition}
      >
        <SnackbarContent message={message} sx={style} />
      </Snackbar>
      {children}
    </AppContext.Provider>
  );
};

// Create the context with a default value
const AppContext = createContext<AppContextValue | undefined>(undefined);

export const useAppContext = () => {
  const context = useContext(AppContext);

  if (context === undefined) {
    throw new Error("useAppContext must be used within a AppProvider");
  }
  return context;
};
