
import { createTheme } from '@mui/material/styles';

export const lightTheme = createTheme({
    typography: {
        fontFamily: [
            "Roboto",
            "Helvetica",
            "Arial",
            "sans-serif"
        ].join(','),
    },
    palette: {
        mode: 'light',
        primary: {
            main: '#0F3C53',
            contrastText: "#fff"
        },
        secondary: {
            main: '#0066CC',
            contrastText: "#fff"
        },
        background: {
            default: '#0000001f',
            paper: '#fff',
        },
        error: {
            main: '#a21212',
            contrastText: "#fff"
        },
        warning: {
            main: '#b75b1a',
            contrastText: "#1E53BB"
        },
        success: {
            main: '#34AC14',
            contrastText: "#fff"
        },
    },
});

export const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#1E53BB',
        },
        secondary: {
            main: '#34AC14',
        },
        background: {
            default: '#02010A',
            paper: '#000b5e',
        },
    },
});
