import React, { memo } from 'react';
import { Box } from "@mui/material";
import { TailSpin } from "react-loader-spinner";
import { useAppContext } from "../../AppContext";

interface LoadingProps {
    alignItems?: string;
}

export const Loading: React.FC<LoadingProps> = memo(({alignItems = 'center'}) => {
    const { state } = useAppContext();

    return (
        <Box sx={{
            display: 'flex',
            width: '100wv',
            height: '100vh',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems
        }}>
            <TailSpin
                visible={true}
                height="80"
                width="80"
                color={state.lightTheme.palette.primary.main}
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
            />
        </Box>
    )
})