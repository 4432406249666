import React, { Suspense, memo } from "react";
import { Profiler } from "react";
import { LazyDrawerNavigationProps } from "../../app-interfaces/interfaces";
import { Loading } from "../shared/Loading";

const DrawerNavigation = React.lazy(
  () =>
    import("./DrawerNavigation").then((module) => ({
      default: module.DrawerNavigation,
    })) as Promise<{ default: React.ComponentType<LazyDrawerNavigationProps> }>
);

export const LazyDrawerNavigation: React.FC<LazyDrawerNavigationProps> = memo(
  function LazyDrawerNavigation(props: LazyDrawerNavigationProps) {
    const callback = (
      id: string,
      phase: string,
      actualDuration: number,
      baseDuration: number,
      startTime: number,
      commitTime: number
    ) => {
      console.log(
        `Profiler ${id} - Phase: ${phase}, Actual Duration: ${actualDuration}ms`
      );
    };

    return (
      <Suspense fallback={<Loading />}>
        <Profiler id="DrawerNavigation" onRender={callback}>
          <DrawerNavigation {...props} />
        </Profiler>
      </Suspense>
    );
  }
);
