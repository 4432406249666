import React, { Suspense } from "react";
import { Loading } from "../shared/Loading";

const DynamicComponent = React.lazy(() => import("./DynamicComponent"));

interface LazyDynamicComponentProps {
  data: any;
  geniusURL: any;
  isFooter: boolean;
  isNotFound: boolean;
}

export const LazyDynamicComponent: React.FC<LazyDynamicComponentProps> = (
  props
) => {
  return (
    <Suspense fallback={<Loading alignItems="flex-start" />}>
      <DynamicComponent {...props} />
    </Suspense>
  );
};
